<template>
  <div id="DiseaseSciencePopularization">
    <!--头部导航-->
    <div style="z-index:9999;position:fixed;top:0;left: 0;">
      <baseNav></baseNav>
    </div>
    <!--内容-->
    <div class="diseaseCon">
      <!--内容头部-->
      <div class="commenHead">
        <div class="about_tit">
          <p v-if="diseaseData.title&&diseaseData.title.length>0"
             class="animated bounceInLeft"
             v-html="diseaseData.title[0].title"
          style="width:35.555vw;"></p>
        </div>
<!--        <div class="about_tit_text">-->
<!--          <p class="animated bounceInLeft">帮助您了解相关疾病知识</p>-->
<!--        </div>-->
      </div>

      <!--精兵科普详情-->
      <div class="diseaseBox">
        <div class="disease_tit_con" v-html="kepuData.content"></div>

        <div class="kepuVideo" v-if="kepuVideo.length>0">
          <video :src="kepuVideo" controls ></video>
        </div>
      </div>

    </div>

    <div class="footer" style="margin-top:13.888vw">
      <Copyright></Copyright>
    </div>

  </div>
</template>

<script>

</script>
<script>
import baseNav from "./baseNav";
import {GetDiseaseKepu,GetIndexDoc} from "@/api/api";
import Copyright from "@/component/Copyright";

export default {
  data(){
    return{
      kepuData:{},
      kepuVideo:'',
    // 疾病科普banner的title
      diseaseData:{}

    }
  },

  mounted() {
    this.toGetDiseaseKepu()
    //获取疾病科普banner数据
    this.toGetIndexDoc(4)

  },
  methods:{
    //获取疾病科普页面banner数据
    toGetIndexDoc(data){
      let _this=this
      GetIndexDoc(data)
          .then(r=>{
            let res=r.data
            if(res.status!=1){
              _this.diseaseData=[]
              return
            }
            let apiIndex=res.data
            _this.diseaseData=apiIndex
            console.log("-------------")
            console.log(_this.diseaseData)
          })
    },
    // 获取疾病科普数据
    toGetDiseaseKepu(){
      let _this=this
      GetDiseaseKepu()
        .then(r=>{
          let res=r.data
          if(res.status!=1){
            _this.kepuData={}
            _this.kepuVideo=''
            return
          }
          _this.kepuData=res.data
          _this.kepuVideo=res.data.video_file
          console.log("=================")
          console.log( _this.kepuVideo.length)
        })

    }
  },
  components: {
    baseNav,
    Copyright
  }
}
</script>
<style>
#DiseaseSciencePopularization .diseaseBox .disease_tit_con img{
  width: calc(100vw - 22.222vw)!important;
  height: auto!important;
}
#DiseaseSciencePopularization .diseaseBox .disease_tit_con {
  font-size:3.3333vw;
  line-height: 5.83333vw;
  padding-top:5.5555vw;
  text-align: left;
}
</style>
<style lang="less" scoped>
#DiseaseSciencePopularization{
  background-color: #071D42;
  min-height: 100vh;
}
.diseaseCon{
  padding-top: 13.888vw;
  .commenHead{
    height: 42.222vw;
    padding-top:11.111vw;
    background: url("../assets/images/pic_banner_jibingkepu_m@2x.png") no-repeat center center;
    background-size:100%;
    .about_tit_text p{
      margin-top:1.666vw;
    }
  }

  .diseaseBox{
    margin:0 6.666vw;
    padding:0 4.444vw 5.555vw;
    border-radius: 1.666vw;
    background-color: #fff;
    .kepuVideo video{
      margin-top:5.555vw;
      // display: none;
      width: calc(100vw - 22.222vw);
      height: auto;
      border-radius: 1.666vw;
      background-color: #333;
      object-fit: fill;
    }

  }

}
</style>